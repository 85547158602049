import React from 'react';
import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import ComingSoon from './pages/comingSoon';

// Define your main App component
const App = () => {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<ComingSoon />} />
      <Route path="*" element={<ComingSoon />} />
      {/* <Route path="/" element={<HoComingSoonme />} />
      <Route path="/about" element={<About />} />
      <Route path="/login" element={<Login />} /> */}
    </Routes>
  </Router>
  );
};

export default App;
