import React from 'react';
import './comingSoon.css';

const ComingSoon = () => {
  return (
    <>
    <div className="bgimg">
     <div className="internal">
     <div className='coming-txt'>
      <h2 className="title">BackpackerBuddy</h2>
      <div className='tagline'>
          <span>
          Discover.
          </span>
          <span>
          Connect.
          </span>
          <span>
          Adventure.
          </span>
      </div>
     <h6 className="coming-soon">Coming Soon!</h6>
     </div>
  </div>
  </div>
  </>
  );
};

export default ComingSoon;
